<template>
  <div>
    <v-fade-transition mode="out-in">
      <component :is="layout">
        <router-view />
      </component>
    </v-fade-transition>
  </div>
</template>

<script>
import InnerSystemLayout from "@/layout/InnerSystemLayout.vue"; // Adjust the path
import loginLayout from "@/layout/login-layout.vue"; // Adjust the path

export default {
  name: "App",
  computed: {
    layout() {
      return this.$route.meta.layout === "InnerSystemLayout"
        ? InnerSystemLayout
        : loginLayout;
    },
  },
};
</script>

<style>
/* Your styles here */
</style>

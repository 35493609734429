import axios from "axios";
import config from "../config/config.js";

import router from "../router";
import store from "../store";
// creating custom instances
export const public_url = axios.create();
export const private_url = axios.create();

// configure baseURL

private_url.defaults.baseURL = config.baseURL;
public_url.defaults.baseURL = config.baseURL;

// define request interceptors
private_url.interceptors.request.use((request) => {
  
  let access_token = store.getters["auth/getToken"];
  request.headers["Authorization"] = "Bearer".concat(" ", access_token);
  return request;
});

//define response interceptors
private_url.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    return Promise.reject(error);
  }
);

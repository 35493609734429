import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import HomeView from "../views/HomeView.vue";
import InnerSystemLayout from "../layout/InnerSystemLayout";
import PaymentLayout from "../layout/PaymentLayout";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Admin.vue"),
  },

  {
    path: "/savedleads",
    name: "savedleads",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SavedLeads.vue"),
  },

  {
    path: "/forgot",
    name: "forgot",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      layout: "login-layout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ForgotPassword.vue"),
  },
  {
    path: "/resetForgotPassword/:param",
    name: "resetForgotPassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ResetForgotPassword.vue"
      ),
  },
  {
    path: "/",
    name: "login",
    meta: {
      layout: "login-layout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/test",
    name: "test",
    meta: {
      layout: "InnerSystemLayout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/testlogin.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      layout: "login-layout",
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Register.vue"),
  },
  {
    path: "/findleads",
    name: "findleads",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Profile.vue"),
  },
  {
    path: "/subscription",
    name: "subscription",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Subscription.vue"),
  },
  {
    path: "/account",
    name: "account",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Account.vue"),
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ResetPassword.vue"),
  },
  {
    path: "/success",
    name: "success",
    meta: {
      layout: "PaymentLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Success.vue"),
  },
  {
    path: "/upgrade",
    name: "upgrade",
    meta: {
      layout: "InnerSystemLayout",
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Plans.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/getToken"]) {
      next({
        name: "login",
      });
      return; // Stop the execution here
    } else {
      next(); // Continue routing
    }
  } else {
    if (store.getters["auth/getToken"]) {
      next({ name: "dashboard" });
      return; // Stop the execution here
    } else {
      next(); // Continue routing
    }
  }
});

export default router;

<template>
  <v-app>
    <v-row>
      <v-col cols="12">
        <v-app-bar color="white" elevate-on-scroll class="appBar">
          <div>
            <a href="/dashboard">
              <img src="../assets/logo2.png" width="150px" />
            </a>
          </div>
          <div class="center hide-on-sm-and-down">
            <v-tabs color="error">
              <v-tab class="ml-2" to="/dashboard">
                <v-icon class="mr-1"> mdi-view-dashboard </v-icon>
                Dashboard
              </v-tab>

              <v-tab class="ml-1" to="/findleads">
                <v-icon class="mr-1"> mdi-magnet-on </v-icon>

                Find Leads
              </v-tab>

              <v-tab class="ml-1" to="upgrade">
                <v-icon class="mr-1">
                  mdi-checkbox-multiple-marked-circle
                </v-icon>

                Upgrade
              </v-tab>

              <v-tab class="ml-1" to="account">
                <v-icon class="mr-1"> mdi-account-circle </v-icon>
                Account
              </v-tab>

              <v-tab class="ml-1" to="/savedleads">
                <v-icon class="mr-1"> mdi-cloud-download </v-icon>

                Saved Leads
              </v-tab>
            </v-tabs>
          </div>

          <v-spacer></v-spacer>
          <div class="mr-5 mt-5">
            <v-list-item-title class="-body-2"
              >Leads Remaining {{ getuserSubscription.searchleadremaining }}/{{
                getuserSubscription.lead_plan.search_lead
              }}</v-list-item-title
            >
            <v-divider color="red"></v-divider>
            <p>
              <strong class="red--text">Upgrade for new leads</strong>
            </p>
          </div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-list-item-avatar>
                  <!-- Check if getuserimg is not an empty string -->
                  <template
                    v-if="getuserimg !== 'https://backend.leadady.com/publica'"
                  >
                    <v-img :src="getuserimg"></v-img>
                  </template>

                  <!-- If getuserimg is an empty string, show another content -->
                  <template v-else>
                    <!-- Add your alternative content here -->
                    <v-icon large>mdi-account-circle</v-icon>
                  </template>
                </v-list-item-avatar>
              </v-btn>
            </template>
            <v-list style="width: 200px">
              <v-list-item to="/subscription">
                <v-list-item-title>
                  <v-icon class="mr-3">mdi-newspaper</v-icon>
                  Subscription
                </v-list-item-title>
              </v-list-item>

              <v-list-item to="/resetpassword">
                <v-list-item-title>
                  <v-icon class="mr-3">mdi-lock-reset</v-icon>
                  Reset Password
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-icon color="red en-2" @click="logout"> mdi-logout </v-icon>
        </v-app-bar>
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-main class="main-background">
      <v-container fluid>
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>

    <!-- Dynamic content from slots -->

    <!-- <slot></slot> -->
    <!-- 
    <v-footer class="d-flex justify-center">
      <v-col cols="auto">
        <v-btn color="primary" @click="dialog = true">
          
        </v-btn>
      </v-col>
    </v-footer> -->
    <template>
      <div>
        <v-dialog v-model="dialog" width="auto">
          <v-card class="mx-auto">
            <div class="d-flex justify-center mx-6 py-10 bg-surface-variant">
              <v-card
                class="mx-auto"
                width="300"
                height="500"
                color="#E0E0E0"
                variant="outlined"
              >
                <v-card-title class="bold"> Free Plan </v-card-title>
                <v-card-> Suitable for individuals </v-card->

                <v-card-title class="bold"> Free </v-card-title>
                <v-card-> 10 Searches/Day </v-card->
                <v-card-> 10 Pages Download/Day </v-card->
              </v-card>
              <v-card
                class="mx-auto ml-5"
                width="350"
                variant="outlined"
                color="#E0E0E0"
              >
                <v-card-title class="bold"> Standard </v-card-title>
                <v-card-> Suitable for Teams </v-card->

                <v-card-title class="bold"> $59/Month </v-card-title>
                <v-card-> 1000 Searches/Day </v-card->
                <v-card-> 1000 Pages Download/Day </v-card->
                <v-btn color="primary" @click="redirectToCheckout('Standard')"
                  >Buy Now</v-btn
                >
              </v-card>
              <v-card
                class="mx-auto ml-5"
                width="350"
                variant="outlined"
                color="#E0E0E0"
              >
                <v-card-title class="bold"> Premium </v-card-title>
                <v-card-> Suitable for Enterprise </v-card->

                <v-card-title class="bold"> $159/Month </v-card-title>
                <v-card-> Unlimited Searches/Day </v-card->
                <v-card-> Unlimited Pages Download/Day </v-card->
                <v-btn color="primary" @click="redirectToCheckout('Premium')"
                  >Buy Now</v-btn
                >
              </v-card>
            </div>
            <!-- <v-card-actions>
              <v-btn color="primary" block @click="dialog = false">Close</v-btn>
            </v-card-actions> -->
          </v-card>
        </v-dialog>
      </div>
    </template>
  </v-app>
</template>

<script>
import { private_url } from "@/store/axios";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      payLink: "",
      paydailog: false,
    };
  },

  computed: {
    ...mapGetters("auth", [
      "getuserEmail",
      "getuserplan",
      "getuserSubscription",
      "getuserimg",
    ]),
  },
  methods: {
    ...mapActions("subscription", ["subscribePlan", "generatePayLink"]),

    redirectToCheckout(Plan) {
      try {
        const response = this.generatePayLink(Plan).then((response) => {
          const links = response.data.data.links;

          for (const link of links) {
            if (link.rel === "approve") {
              const checkoutUrl = link.href;

              window.location.href = checkoutUrl;
              localStorage.clear();
            }
          }
        });
      } catch (error) {
        console.error("Error generating pay link:", error);
      }
    },

    logout() {
      const apiUrl = "logout";
      return private_url
        .get(apiUrl)
        .then((response) => {
          localStorage.clear();
          this.$router.go("/");
        })
        .catch(() => {
          localStorage.clear();
          this.$router.go("login");
          console.log("error- logout", event);
        });
    },
  },
};
</script>
<style scoped>
.appBar {
  border-bottom: 0.5px solid rgb(191, 191, 191) !important; /* Try using !important to ensure the style takes precedence */
}
</style>

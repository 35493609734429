import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "../store/modules/auth";
import lead from "../store/modules/lead";
import subscription from "../store/modules/subscription";
import plan from "../store/modules/plan";
import paddle from "../store/modules/paddle";
import saveleads from "../store/modules/saveleads";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    lead,
    subscription,
    plan,
    paddle,
    saveleads,
  },
});

import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  token: "",
  userEmail: "",
  userplan: "",
  expiredate: "",
  createddate: "",
  userSubscription: "",
  imgurl: "",
  UserCountry: "",
  UserCity: "",
};

const getters = {
  getToken: (state) => state.token,
  getuserEmail: (state) => state.userEmail,
  getuserimg: (state) => state.imgurl,
  getuserplan: (state) => state.userplan,
  getexpireDate: (state) => state.expiredate,
  getcreateddate: (state) => state.createddate,
  getuserSubscription: (state) => state.userSubscription,
  getUserCountry: (state) => state.UserCountry,
  getUserCity: (state) => state.UserCity,
};

const mutations = {
  addToken(state, token) {
    state.token = token;
  },
  addemail(state, email) {
    state.userEmail = email;
  },
  addimg(state, img) {
    state.imgurl = img;
  },
  addplan(state, plan) {
    state.userplan = plan;
  },
  addexpire(state, expires_at) {
    state.expiredate = expires_at;
  },
  addcreatedat(state, expires_at) {
    state.createddate = expires_at;
  },
  adduserSub(state, userSubscription) {
    state.userSubscription = userSubscription;
  },
  updateUserSubscription(state, value) {
    state.userSubscription.searchleadremaining -= value;
  },
  addCountryCity(state, payload) {
    console.log("coungrypayload", payload);
    state.UserCountry = payload.userCountry;
    state.UserCity = payload.userCity;
  },
};

const actions = {
  register({ commit }, payload) {
    const apiUrl = `register`;
    return public_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },

  login({ commit }, payload) {
    const apiUrl = `login`;
    return public_url.post(apiUrl, payload).then((response) => {
      commit("addToken", response.data["token"]);
      commit("addemail", response.data["user"].email);
      commit("addimg", response.data["user"].imageurl);
      commit("addplan", response.data["subscription"].lead_plan.name);
      commit("addexpire", response.data["subscription"].expires_at);
      commit("addcreatedat", response.data["subscription"].created_at);
      commit("adduserSub", response.data["subscription"]);
      return response;
    });
  },

  getUserInfo({ commit }) {
    const apiUrl = `getuserinfo`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
  updateUserInfo({ commit }, payload) {
    const apiUrl = `updateuserinfo`;
    return private_url.post(apiUrl, payload).then((response) => {
      commit("addimg", response.data.data.url);
      return response;
    });
  },
  ResetPassword({ commit }, payload) {
    const apiUrl = `ResetPassword`;
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },
  PasswordForgot({ commit }, payload) {
    const apiUrl = `passwordlinkSent`;
    return public_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },
  passwordForgotreset({ commit }, payload) {
    const apiUrl = `passwordForgotreset`;
    return public_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },
  getUserInfosub({ commit }) {
    const apiUrl = `getusersubscription`;
    return private_url.get(apiUrl).then((response) => {
      commit("adduserSub", response.data.data);
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};

import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {};

const getters = {};

const mutations = {};

const actions = {
    
  getPlans() {
    const apiUrl = `leadplans`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};

<template>
  <v-app>
    <v-main>
      <v-container fluid class="full" style="padding: 0px">
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.main-background {
  background: #e0e0e0;
  /* Fallback background color (dark grey) */
  // background: linear-gradient(90deg, #333 0%, #cb0a0b 100%);
}
</style>

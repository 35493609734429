import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {
  selectedid: "",
};

const getters = {
  getitemid: (state) => state.selectedid,
};

const mutations = {
  additemid(state, id) {
    state.selectedid = id;
  },
};

const actions = {
  fetchLeads({ commit, state }, payload) {
    const apiUrl = `leads/${payload.page}`;
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },
  emailGet({ commit }, payload) {
    console.log("check", payload);
    const apiUrl = `getemail/${payload.id}/${payload.country}`;
    return private_url.get(apiUrl, payload).then((response) => {
      return response;
    });
  },
  filtersleads({ commit }, payload) {
    const apiUrl = `filter/${payload.page}`;
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },

  excelDownload({ commit }, payload) {
    const apiUrl = "exportexcel";
    return private_url
      .post(apiUrl, payload, { responseType: "blob" })
      .then((response) => {
        return response;
      });
  },
  freeexcelDownload({ commit }, payload) {
    const apiUrl = "freeexportexcel";
    return private_url
      .post(apiUrl, payload, { responseType: "blob" })
      .then((response) => {
        return response;
      });
  },

  bulkexcelDownload({ commit }, payload) {
    const apiUrl = "bulkexportexcel";
    return private_url
      .post(apiUrl, payload, { responseType: "blob" })
      .then((response) => {
        return response;
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};

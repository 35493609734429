import { private_url, public_url } from "../axios";
import createPersistedState from "vuex-persistedstate";
const plugins = [createPersistedState()];
const namespaced = true;

const state = {};

const getters = {};

const mutations = {};
const actions = {
  postspecifiedFields({ commit }, payload) {
    const apiUrl = `saveselectedrows`;
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },
  postbulksaveFields({ commit }, payload) {
    const apiUrl = `bulksave`;
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },
  getSavedLeads() {
    const apiUrl = `getsaveleads`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
  getSavedLeadsforlist({ commit }, payload) {
    const apiUrl = `getleadsforlist/${payload.itemid}/${payload.page}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
  changeListName({ commit }, payload) {
    const apiUrl = `updateListName`;
    return private_url.post(apiUrl, payload).then((response) => {
      return response;
    });
  },
  deleteListName({ commit }, id) {
    const apiUrl = `deletelist/${id}`;
    return private_url.get(apiUrl).then((response) => {
      return response;
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
  plugins,
};
